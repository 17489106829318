<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="日期" prop="authUserName">
                    <el-date-picker
                            clearable
                            style="width: 220px;"
                            v-model="selectDate"
                            type="daterange"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            @change="selectedDate"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                    ></el-date-picker>
                </el-form-item>


                <el-form-item label="支付状态"    prop="s_phone">
                    <el-select v-model="searchForm.status"  clearable  placeholder="支付状态">
                        <el-option label="待支付" :value='1'></el-option>
                        <el-option label="已支付" :value='2'></el-option>
                    </el-select>
                </el-form-item>



                <el-form-item label="用户电话" prop="phone">
                    <el-input v-model="searchForm.phone" style="width: 220px;" clearable
                              placeholder="用户电话"></el-input>
                </el-form-item>

                <el-form-item style="margin-top: auto;margin-right: 0;">
                    <div class="fill-btn" @click="orderGoods">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left:20px; margin-right: auto;">
                    <el-button type="warning" @click="excelTeamOrder">导出</el-button>
                </el-form-item>

                <!--<el-form-item style="margin-top: auto;margin-left: auto;">-->
                    <!--<div>奖励总积分：<strong style="color: #ff4444;font-size: 20px">{{allPrice}}</strong></div>-->
                <!--</el-form-item>-->

            </el-form>
        </div>

        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="created_at" label="下单日期" width="148"></el-table-column>
                <el-table-column prop="number" label="订单号" min-width="120px"></el-table-column>
                <el-table-column prop="phone" label="用户" min-width="120px"></el-table-column>
                <el-table-column prop="price" label="金额" min-width="120px"></el-table-column>
                <el-table-column width="80" label="支付">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-tag v-if="row.status == 1"  round>待支付</el-tag>
                            <el-tag v-if="row.status == 2" type="success" round>已支付</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="store_price" label="店铺收益" min-width="120px"></el-table-column>
                <el-table-column  v-if="this.user_type == 1"  prop="platform_price" label="平台收益" min-width="120px"></el-table-column> -->
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>

    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                selectDate: [],
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: '',
                    name: '',
                    type:'',
                    phone:'',
                    amount:''

                },
                form: {
                    nickname: ''
                },
                editForm: {
                    role_ids: [],
                    nickname: '',
                    username: '',
                    password: '',
                    status: ''
                },
                currentId: '',
                tableData: [],
                typeList: [],
                addTeacher: {},
                allPrice: 0,
                all: [],
                amount:[],
                rate:[],
                year:[],
                company:[],
                user_type:''

            }
        },
        created() {
            this.orderGoods();
            this.user_type = window.sessionStorage.getItem('type')
        },
        computed: {
            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.orderGoods();
            },
        },
        methods: {
            //头像
            handleAvatarSuccess(res, file) {

                this.editForm.head = res.url;
                this.addTeacher.head = res.url;
                console.log(this.addTeacher.head);
            },
            // 选择日期
            selectedDate(e) {
                if (e) {
                    this.searchForm.start_time = e[0];
                    this.searchForm.end_time = e[1];
                    console.log(this.searchObj);
                } else {
                    this.searchForm.start_time = '';
                    this.searchForm.end_time = '';
                }
            },
            //公司列表
            async companyList() {

                let [e, data] = await this.$api.companyList();

                if (data.code === 200) {
                    this.company = data.data;
                }
            },
            //类型
            async getTeacherTypeList() {
                let [e, data] = await this.$api.ruleList();
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.typeList = data.data;
                }
            },
            //类型
            async getSearch() {
                let [e, data] = await this.$api.orderAmount();
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.amount = data.amount;
                    this.rate = data.rate;
                    this.year = data.year;
                }
            },

            //产品列表
            async getProductList() {

                let [e, data] = await this.$api.productAll(this.searchForm);
                if (e) return;
                if (data.code === 200) {
                    console.log(data);
                    this.all = data.data;
                    // this.total = data.data.total;
                }
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/png';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG,png 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }

            },
            handShowAdd() {
                this.editForm = [];
                this.editForm.nickname = '';
                this.editForm.username = '';
                this.editForm.password = '';

                this.showAdd = true;
            },
            handEditShow(row) {


                this.editForm = row;

                this.showEdit = true;
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取分销列表
            async orderGoods() {

                console.log(this.searchForm);
                let [e, data] = await this.$api.orderGoods(this.searchForm);
                if (e) return;

                if (data.code === 200) {
                    this.tableData = data.data.data;

                    this.total = data.data.total;

                }
            },

            //导出
            //导出
            async excelTeamOrder() {
                this.$downFile('priceExport', this.searchForm, '充值');
            },

            //添加分类
            async addType() {
                this.loading = true;
                let [e, data] = await this.$api.createType(this.addTeacher);
                if (e) return;
                if (data.code === 200) {
                    // this.getTeacherList();
                    this.loading = false;
                    this.showAdd = false;
                    this.orderGoods();
                    this.$message.success('添加成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            //类型编辑
            async saveStatus({id}) {
                this.editing = true;
                let [e, data] = await this.$api.saveUserTeacherStatus({id: id});
                this.editing = false;
                if (e) return;
                if (data.code === 200) {
                    this.showEdit = false;
                    this.orderGoods();
                    this.$message.success('保存成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            }
        },
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
